<template>
  <div
    class="tournament-history-entry w-100 row align-items-center justify-content-between mb-3"
  >
    <div class="game-img-section col-2">
      <img draggable="false" :src="dataHistory.image_path" />
    </div>
    <div class="history-data-section col-10">
      <div class="w-100 history-data-top-row d-inline-flex position-relative">
        <div
          class="rank-flag bg-img position-absolute"
          :class="[dataHistory.rank === 1 ? 'red' : 'blue']"
        >
          <span class="rank-number text-white bold to-center-abs text-center">{{
            dataHistory.rank
          }}</span>
        </div>
        <div class="w-50 d-flex align-items-center">
          <span class="text-uppercase bold light-yellow headline2">{{
            $t("tournaments.history.info.datetime2") +
            " " +
            new Date(dataHistory.end_date).toLocaleDateString(
              $store.state.locale,
              {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }
            )
          }}</span>
        </div>
      </div>
      <div class="w-100 history-data-bottom-row">
        <div class="w-50 d-inline-flex justify-content-between">
          <div class="history-data-column align-items-center">
            <span class="text-uppercase grey medium">{{
              $t("tournaments.info.placement")
            }}</span
            ><span class="text-white bold glow">{{ dataHistory.rank }}</span>
          </div>
          <div class="history-data-column align-items-center mr-5">
            <span class="text-uppercase grey medium">{{
              $t("raffle_logs.winners.column_title.reward_new")
            }}</span>
            <div class="d-inline-flex align-items-center justify-content-end">
              <span class="text-white bold glow">{{
                formatNumber(dataHistory.amount)
              }}</span
              ><img draggable="false" src="@/assets/img/common/coin1.png" />
            </div>
          </div>
        </div>
        <div class="w-50 d-inline-flex">
          <div class="w-75 history-data-column align-items-start">
            <span class="text-uppercase grey medium pb-2">{{
              $t("tournaments.info.mode")
            }}</span
            ><span class="blue bold">{{ tournamentMode }}</span>
          </div>
          <div class="history-data-column align-items-start">
            <span class="text-uppercase grey medium pb-2">{{
              $t("tournaments.info.duration")
            }}</span>
            <span class="blue bold">{{ tournamentDuration }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TournamentHistoryEntry",
  props: {
    dataHistory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tournamentMode() {
      if (!this.dataHistory) {
        return;
      }

      let type = this.dataHistory.type;
      let limit = "";
      if (this.dataHistory.limit) {
        limit = this.formatNumber(this.dataHistory.limit);
      }

      return this.$t("tournaments.type." + type.toLowerCase(), { 0: limit });
    },
    tournamentDuration() {
      if (!this.dataHistory) return 0;
      let start = new Date(this.dataHistory.start_date);
      let end = new Date(this.dataHistory.end_date);
      let diff = end - start;
      let minutes = diff / 1000 / 60;
      if (minutes < 60) {
        return minutes.toString() + " " + this.$t("time.minute>other");
      } else if (minutes === 60) {
        return "1  " + this.$t("time.hour>one");
      } else {
        let hours = minutes / 60;
        return hours.toString() + " " + this.$t("time.hour>other");
      }
    },
  },
};
</script>
<style lang="scss">
.tournament-history-entry {
  .game-img-section {
    img {
      max-height: 126px;
    }
  }
  .history-data-section {
    width: 80%;

    .headline1 {
      font-size: 24px;
    }
    .headline2 {
      font-size: 21px;
    }
  }
  .light-yellow {
    color: #fff8b7;
  }
  .grey {
    color: #8e8e8e;
    font-size: 16px;
  }
  .glow {
    font-size: 2.25rem;
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 4px rgba(0, 94, 110, 0.8);
  }

  .history-data-top-row {
    font-size: 24px;
    background-color: rgba(5, 13, 28, 0.31);
    padding: 7px 0 6px 88px;
    height: 40px;

    .rank-flag {
      width: 51px;
      height: 51px;
      top: -6px;
      left: 24px;
      .rank-number {
        font-size: 24px;
        text-shadow: 0 0 4px black;
        bottom: 5px;
      }
      &.red {
        background-image: url(~@/assets/img/tournament/icn-flag-red.png);
      }
      &.blue {
        background-image: url(~@/assets/img/tournament/icn-flag-blue.png);
      }
    }
  }

  .history-data-bottom-row {
    background-color: $dark-blue-06;
    padding: 0 0 8px 88px;
  }

  .history-data-column {
    display: flex;
    flex-direction: column;
    img {
      height: 32px;
      width: 32px;
      filter: drop-shadow(0 2px 4px black);
      margin-left: 13px;
    }
  }
}
</style>
