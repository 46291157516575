<template>
  <b-col id="achievement-page" cols="12" class="p-0">
    <span class="subline white-shadow text-uppercase medium d-flex pb-22">{{
      $t("tournaments.achievements.headline")
    }}</span>
    <UserAchievementsContainer
      v-if="dataTournamentAchievements && !error"
      :custom-achievements="dataTournamentAchievements"
      :show-deco="false"
    />
    <ErrorPlaceholder v-else />

    <b-row
      v-if="dataTournamentAchievements && error"
      no-gutters
      class="mt-5 mb-3"
    >
      <div class="col-12">
        <div class="yellow-text subline">
          {{ $t("tournaments.achievements.placements") }}
        </div>
      </div>
    </b-row>
    <span class="subline text-white text-uppercase medium d-flex pb-2 mt-5">{{
      $t("tournaments.achievements.placements")
    }}</span>
    <b-row
      v-if="dataHistory && !error"
      no-gutters
      align-h="center"
      class="place-row text-center text-normal"
    >
      <b-col
        v-for="(n, index) in 4"
        :key="index"
        class="placement-field d-flex flex-column align-items-center justify-content-around"
        :class="{ first: index === 0 }"
        ><div
          class="placement-badge bg-img"
          :style="{
            backgroundImage:
              'url(' +
              require(`@/assets/img/tournament/tourney_badge_${index}.png`) +
              ')',
          }"
        ></div>
        <span class="text-white bold text-uppercase glow">
          {{ $t(`tournaments.achievements.placements_${index}`) }}</span
        ><span class="blue text-uppercase">{{
          $t("tournaments.achievements.placements.text_top")
        }}</span>
        <div
          class="placement-display d-flex align-items-center justify-content-center text-white glow"
        >
          {{ amountPlace(index) }}
        </div>
        <span class="blue text-uppercase">{{
          $t(`tournaments.achievements.placements.text_bottom_${index}`)
        }}</span></b-col
      >
    </b-row>
  </b-col>
</template>

<script>
import UserAchievementsContainer from "../user-achievements/user-achievements-container.vue";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";

export default {
  name: "TournamentachievementPage",
  components: {
    UserAchievementsContainer,
    ErrorPlaceholder,
  },
  props: {
    dataHistory: { type: Array, default: null },
    dataTournamentAchievements: { type: Array, default: null },
    error: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    amountPlace(index) {
      let amount = 0;
      for (let prop in this.dataHistory) {
        if (index === 3) {
          if (this.dataHistory[prop].rank > 3) {
            amount++;
          }
        } else {
          if (this.dataHistory[prop].rank === index + 1) {
            amount++;
          }
        }
      }

      return amount;
    },
  },
};
</script>

<style lang="scss">
#achievement-page {
  #user-achievements-container {
    padding-left: 0;
    & > .col-12 {
      padding-left: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#achievement-page {
  position: relative;
  z-index: 2;
  max-width: 1500px;
  margin: auto;

  .subline {
    font-size: 32px;
  }
  .place-row {
    flex-wrap: nowrap;

    .placement-field {
      background-image: radial-gradient(
        circle at 50% 46%,
        #274b60 0%,
        #0a1d28 72%
      );
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5),
        0 0 32px 0 rgba(0, 0, 0, 0.5);
      border-radius: 16px;
      height: 368px;
      max-width: 296px;
      &.first {
        background-image: radial-gradient(
          circle at 50% 46%,
          #274b60 0%,
          #0a1d28 72%
        );
        border: 3px solid #fff8b7;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5),
          0 0 32px 0 rgba(0, 0, 0, 0.5);
        border-radius: 16px;
      }
      .text-white {
        &.glow {
          font-size: 2.25rem;
          text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
            0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
            0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
        }
      }
      &:not(:last-of-type) {
        margin-right: 16px;
      }
      .placement-badge {
        width: 179px;
        height: 140px;
      }

      .placement-display {
        background: #000000;
        box-shadow: 0 3px 0 0 #074f5c;
        border-radius: 8px;
        width: 128px;
        height: 48px;
      }
    }
  }
}
</style>
