<template>
  <b-col id="history-page" cols="12">
    <b-row
      v-if="!error"
      id="tournament-content-row"
      class="history-row"
      no-gutters
    >
      <template v-if="dataHistory && dataHistory.length > 0">
        <b-col cols="12" class="subline text-uppercase white-shadow medium">{{
          $t("tournaments.achievements.placements")
        }}</b-col>
        <b-col v-for="item in dataHistory" :key="item.id" cols="12">
          <TournamentHistoryEntry :data-history="item" />
        </b-col>
      </template>
      <template v-else>
        <div class="placeholder-text yellow-text">
          {{ $t("tournaments.history.placeholder") }}
        </div>
      </template>
    </b-row>
    <ErrorPlaceholder v-else class="mt-3" />
  </b-col>
</template>

<script>
import TournamentHistoryEntry from "@/components/tournament/tournament-history-entry.vue";

import ErrorPlaceholder from "@/components/base/error-placeholder.vue";

export default {
  name: "HistorysPage",
  components: {
    TournamentHistoryEntry,
    ErrorPlaceholder,
  },
  props: {
    dataHistory: {
      type: Array,
      default: () => {
        [];
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.dataHistory) {
      this.$store.commit("setIsPageLoading", false);
    }
  },
};
</script>

<style lang="scss">
#history-page {
  position: relative;
  z-index: 2;

  #tournament-content-row {
    max-width: 1500px;
    margin: auto;
  }

  .history-row {
    min-height: 400px;
    .subline {
      font-size: 32px;
    }
  }

  #placeholder-container {
    height: 300px;
    font-size: 24px;
  }

  .placeholder-text {
    font-family: Ubuntu;
    font-size: 24px;
    color: transparent;
    background-image: linear-gradient(#f7ffff, #ffee51 58%, #8b5a00);
    background-clip: text;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5))
      drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  }
}
</style>
