<template>
  <router-view
    :active-tournaments="activeTournaments"
    :upcoming-tournaments="upcomingTournaments"
    :data-tournament-achievements="dataTournamentAchievements"
    :data-history="dataHistory"
    :error="error"
  />
</template>

<script>
import * as tournamentAPI from "@/API/tournament.js";
import * as AchievementsAPI from "@/API/achievements.js";

export default {
  name: "TournamentPage",
  data() {
    return {
      dataHistory: null,
      dataTournamentAchievements: null,
      loading: true,
      activeTournaments: {},
      upcomingTournaments: {},
      intervalId: null,
      error: false,
    };
  },
  computed: {
    currentTab: function () {
      if (this.tab === null) {
        return;
      }

      return this.tab;
    },
  },
  created() {
    this.intervalId = setInterval(() => {
      this.updateTournaments();
    }, 60000);
  },

  mounted: function () {
    Promise.all([
      this.fetchActiveTournaments(),
      this.fetchUpcomingTournaments(),
      this.fetchDataHistory(),
      this.fetchTournamentAchievements(),
    ])
      .catch((e) => {
        this.error = true;
        console.log(e);
      })
      .finally(() => {
        this.loading = false;
        this.$store.commit("setIsPageLoading", false);
      });
  },

  updated: function () {
    if (!this.loading) {
      this.$store.commit("setIsPageLoading", false);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    fetchActiveTournaments() {
      tournamentAPI.fetchActiveTournaments().then((result) => {
        this.activeTournaments = result.data.data;

        //no entries? set to null
        if (Array.isArray(this.activeTournaments)) {
          this.activeTournaments = null;
          return;
        }
        this.setAdditionalValues(this.activeTournaments);
      });
    },
    fetchUpcomingTournaments() {
      tournamentAPI.fetchUpcomingTournaments().then((result) => {
        this.upcomingTournaments = result.data.data;

        //no entries? set to null
        if (Array.isArray(this.upcomingTournaments)) {
          this.upcomingTournaments = null;
          return;
        }
        this.setAdditionalValues(this.upcomingTournaments);
      });
    },
    updateTournaments() {
      this.fetchActiveTournaments();
      this.fetchUpcomingTournaments();
    },

    //game information are missing. add infos to the object
    setAdditionalValues(tournamentObject) {
      Object.values(tournamentObject).forEach((tourney) => {
        let gamesInStore = this.$store.state.gameplay.games;
        let gameInStore = gamesInStore.find(
          (game) => game.id === tourney.game_id
        );
        Object.assign(tourney, {
          settings: gameInStore.settings,
        });
        if (gameInStore && gameInStore.unlocked) {
          Object.assign(tourney, {
            unlocked: true,
          });
        }
      });
    },

    fetchDataHistory: async function () {
      await tournamentAPI.fetchHistoryTournament().then((result) => {
        this.dataHistory = result.list;
      });
    },
    fetchTournamentAchievements: function () {
      AchievementsAPI.listAchievements()
        .then((res) => {
          let list = Object.values(res.data.data.list);
          this.dataTournamentAchievements = list.filter(
            (item) =>
              item.slug === "wintournamentx" || item.slug === "top10tournamentx"
          );
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
