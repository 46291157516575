<template>
  <b-col id="tournament-main" cols="12">
    <template v-if="!error">
      <b-col
        cols="12"
        class="subline medium text-uppercase white-shadow d-flex pb-2"
      >
        {{ $t("tournaments.subheadline.ongoing") }}
      </b-col>
      <b-row id="tourn-active" class="my-3 position-relative">
        <Spinner
          v-if="isEmpty(activeTournaments)"
          size="medium"
          context="Loading Redirect..."
          classes="dead-center"
        />

        <b-col
          v-for="item in activeTournaments"
          :id="'col-tournament' + item.id"
          :key="item.id"
          xl="3"
          cols="4"
          :class="item.hidden ? 'hide-temp' : ''"
          class="mb-5"
        >
          <ItemTournament :data="item" :showtime="true" :is-active="true" />
        </b-col>
        <div v-if="!activeTournaments" class="empty-placeholder to-center-abs">
          {{ $t("tournaments.placeholder.ongoing") }}
        </div>
      </b-row>
      <b-col cols="12" class="subline medium text-uppercase text-white pb-2">
        {{ $t("tournaments.subheadline.coming_soon.v2") }}
      </b-col>

      <b-row id="tourn-upcoming" class="mt-3 position-relative">
        <Spinner
          v-if="isEmpty(upcomingTournaments)"
          size="medium"
          context="Loading Redirect..."
          classes="dead-center"
        />

        <b-col
          v-for="item in upcomingTournaments"
          :id="'col-tournament' + item.id"
          :key="item.id"
          xl="3"
          cols="4"
          :class="item.hidden ? 'hide-temp' : ''"
          class="mb-5"
        >
          <ItemTournament :data="item" :showtime="true" />
        </b-col>
        <div
          v-if="!upcomingTournaments"
          class="empty-placeholder to-center-abs"
        >
          {{ $t("tournaments.placeholder.coming_soon") }}
        </div>
      </b-row>
    </template>
    <ErrorPlaceholder v-else class="mx-auto mt-3" />
  </b-col>
</template>

<script>
import ItemTournament from "./item-tournament";

import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
import Spinner from "@/components/animation/animation-spinner";

export default {
  name: "TournamentMainPage",
  components: {
    ItemTournament,

    ErrorPlaceholder,
    Spinner,
  },
  props: {
    activeTournaments: {
      type: Object,
      required: false,
      default: null,
    },
    upcomingTournaments: {
      type: Object,
      required: false,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.activeTournaments || this.upcomingTournaments) {
      this.$store.commit("setIsPageLoading", false);
    }
  },

  methods: {
    isEmpty(obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
#tournament-main {
  position: relative;
  z-index: 2;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;

  .subline {
    font-size: 32px;
  }

  .empty-placeholder {
    color: white;
    text-align: center;
    top: 0;
    font-size: 20px;
  }

  .hide-temp {
    opacity: 0;
  }

  .top-space {
    margin-top: 70px;
  }
}
</style>
