<template>
  <div
    v-if="data"
    class="item-tournament d-flex flex-column align-items-center justify-content-between"
  >
    <div v-if="!tournamentUnlocked && data.settings" class="lock-container">
      <div class="lock-text bold text-uppercase text-center to-center-abs">
        {{
          $t("jackpots.choose_jackpot.min_level", {
            0: data.settings.unlock_level,
          })
        }}
      </div>
      <div class="lock to-center-abs">
        <img src="@/assets/img/game/slot-lock.png" class="w-100" />
      </div>
    </div>

    <router-link
      :to="
        tournamentUnlocked && showtime ? '/tournament/game/' + data.game_id : ''
      "
      class="game-link position-relative pb-2 w-100"
      @click.native="playSoundTourneyClick"
    >
      <div
        v-if="data.image_path"
        draggable="false"
        class="slot-img"
        :class="!tournamentUnlocked && data.settings ? 'locked-game' : ''"
        :style="{ backgroundImage: 'url(' + data.image_path + ')' }"
      />
      <div v-else class="slot-img game-placeholder"></div>
      <div
        class="dead-center bg-img play-arrow"
        :class="{ pending: !isActive }"
      ></div>
    </router-link>

    <div v-if="showtime" class="countdown-bar w-100 d-inline-flex mb-3">
      <div class="w-50 d-flex flex-column align-items-center">
        <span class="text-uppercase blue bold display-label">{{
          $t("tournaments.info.duration")
        }}</span>
        <div class="black-display w-100 mr-1 text-center">
          <span class="blue bold">{{ duration }} Min</span>
        </div>
      </div>
      <div class="w-50 d-flex flex-column align-items-center">
        <span class="text-uppercase blue bold display-label"
          ><template v-if="isActive">{{
            $t("tournaments.info.end_time")
          }}</template
          ><template v-else>{{
            $t("tournaments.info.start_time")
          }}</template></span
        >
        <div
          class="black-display w-100 ml-1 text-center"
          :class="[isActive ? 'red' : 'green']"
        >
          <CountdownDigital
            v-if="isActive"
            :time="endDate"
            :with-time-diff="true"
          />
          <CountdownDigital v-else :time="startDate" :with-time-diff="true" />
        </div>
      </div>
    </div>
    <div class="w-100 d-flex flex-column align-items-center">
      <span class="text-uppercase blue bold display-label">{{
        $t("tournaments.info.mode")
      }}</span>
      <div
        class="mode-info black-display w-100 d-flex align-items-center justify-content-center"
      >
        <span class="mode-text blue bold text-center">
          {{ mode }}
        </span>
      </div>
    </div>

    <div class="prize-pool-container to-center-abs">
      <span class="text-uppercase bold text-white"
        >{{ $t("tournaments.info.prize_pool") }}:

        {{ formatNumber(data.amount) }}</span
      ><img
        draggable="false"
        class="ml-3"
        src="@/assets/img/common/coin1.png"
        :style="{
          height: '16px',
        }"
      />
    </div>
  </div>
</template>

<script>
import CountdownDigital from "../base/countdown-digital";

export default {
  name: "ItemTournament",
  components: { CountdownDigital },
  props: {
    data: { type: Object, default: null },
    isActive: { type: Boolean, default: false },
    showtime: { type: Boolean, required: false, default: false },
    dataGames: { type: Array, required: false, default: null },
    infotop: { type: Boolean, required: false, default: false },
  },
  data() {
    return {};
  },

  computed: {
    tournamentUnlocked() {
      if (this.showtime) {
        return (
          this.data.settings.unlock_level <=
          this.$store.getters["user/currentUser"].level
        );
      } else {
        return true;
      }
    },
    startDate: function () {
      if (!this.data) {
        return;
      }

      let then = new Date(this.data.start_date);
      return then.getTime();
    },
    endDate: function () {
      if (!this.data) {
        return;
      }

      let then = new Date(this.data.end_date);
      return then.getTime();
    },

    mode: function () {
      if (!this.data && !Array.isArray(this.data)) {
        return;
      }

      let type = this.data.type;
      let limit = "";
      if (this.data.limit) {
        limit = this.formatNumber(this.data.limit);
      }

      return this.$t("tournaments.type." + type.toLowerCase(), { 0: limit });
    },
    gameName: function () {
      if (!this.data || !this.dataGames) {
        return;
      }

      const game_id = this.data.game_id;
      for (let i = 0; i < this.dataGames.length; i++) {
        if (this.dataGames[i].id === game_id) {
          return this.dataGames[i].title;
        }
      }

      return "";
    },
    duration: function () {
      if (!this.data) {
        return;
      }

      let begin = new Date(this.data.start_date).getTime();
      let then = new Date(this.data.end_date).getTime();

      let distance = then - begin;

      // Time calculations
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      return hours * 60 + minutes;
    },
  },
};
</script>

<style lang="scss"></style>

<style scoped lang="scss">
.item-tournament {
  position: relative;
  width: 100%;
  cursor: pointer;

  .game-link {
    max-width: 350px;
  }

  &:hover .slot-img {
    opacity: 0.5;
  }
  &:hover .play-arrow {
    display: block;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }

  padding: 33px 17px 26px 17px;
  background-image: radial-gradient(circle at 50% 73%, #274b60 0%, #0a1d28 49%);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5),
    0 0 32px 0 rgba(0, 0, 0, 0.5), inset 0 0 8px 0 rgba(0, 0, 0, 0.69),
    inset 0 0 16px 0 rgba(0, 0, 0, 0.6);
  border-radius: 16px;

  .lock {
    z-index: 5;
    bottom: 0;
  }

  .lock-text {
    font-size: 21px;
    z-index: 6;
    top: 25px;
    width: 178px;
    height: 32px;
    border-radius: 2.1px;
    color: white;
    background-image: linear-gradient(#ff0000, #3a0000);
    box-shadow: inset 0 0 4px #ff0000, inset 0 0 8px #ff0000;
  }

  .slot-img {
    width: 100%;
    height: 192px;
    padding: 1.5px;
    object-fit: cover;
    border-radius: 8px;
    background-size: cover;
    background-position: center;

    &.game-placeholder {
      background: url(~@/assets/img/settings/icon-help.svg) center/100% 100%
        no-repeat;
      -webkit-transform: translate3d(0, 0, 0);
    }

    &.locked-game {
      -webkit-filter: grayscale(100%) brightness(50%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%) brightness(50%);

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ */
        &:before {
          content: "";
          background-color: rgba(136, 136, 136, 0.5);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .play-arrow {
    background-image: url(~@/assets/img/tournament/play-hover.png);
    width: 154px;
    height: 154px;
    display: none;
    &.pending {
      background-image: url(~@/assets/img/tournament/pending-hover.png);
    }
  }

  .countdown-bar {
    .black-display {
      span {
        font-size: 21px;
      }
      &.red {
        span {
          color: #ff0000;
        }
      }
      &.green {
        span {
          color: #2da304;
        }
      }
    }
  }
  .display-label {
    font-size: 14px;
  }

  .mode-info {
    height: 48px;
    .mode-text {
      font-size: 16px;
    }
  }

  .prize-pool-container {
    height: 32px;
    top: -15px;
    z-index: 10;
    width: 90%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 8px;
    background-color: black;
    border: solid 2px $light-blue;
    box-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
      0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);

    span {
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6);
      font-size: 17px;
      line-height: 32px;
    }
  }
}
.black-display {
  height: 32px;
  background: #000000;
  box-shadow: 0 3px 0 0 #074f5c;
  border-radius: 8px;
}
</style>
